import React from "react";
import styled from 'styled-components';

import LogoIcon from '../../assets/icons/coloredLogo.png';
import Arrow from '../../assets/icons/blueArrow.svg';

import media from '../../styles/utils/media';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${media.sm`
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        align-items: center;
        justify-content: center;
    `}
`;

export const Description = styled.div`
    width: 100%;
    padding: 0 20px;

    @media(min-height: 700px){
        margin: 30px 0 20px 0;
    }

    > H1 {
        font-size: ${(props) => props.theme.fontSize.S6};
    }
    > p {
        margin: 0;
        font-size: ${(props) => props.theme.fontSize.SP};
        color: ${(props) => props.theme.colors.textP}
    }

    ${media.sm`
        display: flex;
        flex-direction: column;
        
        text-align: center;
        margin-top: 30px;

        > p {
            margin-top: 15px;
            display: block;
        }
    `}
`;

export const Group = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 42.5rem;
    min-height: 30rem;
    width: 100%;
    height: 100%;


    ${media.sm`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 80%;
        height: 70%;
        margin-top: 30px;
    `}

    ${media.md`
        width: 100%;
    `}
`;

export const CardGroup = styled.div`
    display: flex;
    align-items: center;
    ${media.sm`
        display: flex;
        align-items: center;

        width: 840px;
        height: 450px;
    `}
`;

export const Cards = styled.div`
    display: flex;
    align-items: center;

    ${media.sm`
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    `}
`;

export const LeftArrowIcon = styled.button`
    display: none;
    ${media.sm`
        width: 50px;
        height: 50px;
        margin-right: 20px;
        background-color: transparent;
        background-image: url(${Arrow});
        cursor: pointer;
        border: none;
        outline: none;
    `}    
`;

export const RightArrowIcon = styled.button`
    display: none;

    ${media.sm`
        display: none;
        width: 50px;
        height: 50px;
        margin-left: 20px;
        background-color: transparent;
        background-image: url(${Arrow});
        transform: rotate(-180deg);
        cursor: pointer;
        border: none;
        outline: none;
    `}
`;

export const SectionTeam = styled.div`
    display: none;
    ${media.sm`
        display: none;
        justify-content: space-between;
        
        width: 135px;
        height: 12px;
    `}
`;

export const Section = styled.button`
    display: none;

    ${media.sm`
        display: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.navigationButton};
    `} 
`;

export const Logo = styled.div`
    display: none;
    ${media.sm`
        display: block;
        position: absolute;
        top: calc(100% - 70px);
        left: calc(100% - 60px);
        width: 37.42px;
        height: 40px;
        background-image: url(${LogoIcon});
    `}
`;