import { Link } from 'gatsby';
import styled from 'styled-components';

import Instagram from '../../assets/icons/blueInstagramIcon.svg';
import Linkedin from '../../assets/icons/blueLinkedinIcon.svg';
import { H3, P } from '../../styles/Fonts';

import media from '../../styles/utils/media';
import { Icon } from '../CardService/styles';

export const Container = styled.div`
	display: flex;
	align-self:center;
	justify-self:center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0.4em;
	border: 0.3em solid ${(props) => props.theme.colors.primary};
	margin-left: 0.1em;
	@media only screen and (min-width: 792px) {
		width: 250px;
		height: 70vh;
		max-height: 40rem;
		min-height : 350px;
	}


`;

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
    height: 100%;
	margin: 0;
	padding: 0.5vh 2vw;	
	border: 1px solid ${(props) => props.theme.colors.primary};
	
	${media.sm`
	`}
`;

export const ProfilePhoto = styled.div`
	width: 9em;
	height: 9em; 
	margin-top:1em;
	border: solid ${(props) => props.theme.colors.primary} 1px;
	border-radius: 146px;
	overflow:hidden;
    img{
		width: 100%;
		height:100%;
	}
	${media.sm`
	`}
`;

export const TextName = styled(H3)`
	margin-top:0.5em;
	text-align:center;
	${media.sm`
	`}
`;

export const SubName = styled(P)`
	margin: 0;
	color: ${(props) => props.theme.colors.textP};
`;

export const Description = styled(P)`
	/*min-height: 80px;*/
	flex:1;
	margin-top: 0.5em;
	text-align:justify;
	font-weight:600;
	color: ${(props) => props.theme.colors.textP};
	
	${media.sm`
	`}
`;

export const Icons = styled.div`
	display: flex;
	justify-content: center;
	
	padding: 0.5em;

	${media.sm`
		width: 100%;
	`}
`;



export const InstagramIcon = styled.a`
	width: 18px;
	height: 18px;
	margin-right:2em;
	border: none;
	outline: none;
	cursor: pointer;
	background-image: url(${Instagram});
	background-size: cover;
	background-color: transparent;

	${media.sm`
		margin-right:0em;
		width: 21px;
		height: 21px;
		
	`}
`;

export const LinkedinIcon = styled.a`
	width: 17px;
	height: 18px;
	border: none;
	outline: none;
	cursor: pointer;
	background-image: url(${Linkedin});
	background-size: cover;
	background-color: transparent;

	${media.sm`
		width: 19px;
		height: 20px;
		margin-left: 25px;
	`}
`;