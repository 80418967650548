import React from 'react';
import {graphql, PageProps} from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { H1 ,P} from '../styles/Fonts';
import { Container, Description, Logo, CardGroup, Cards, LeftArrowIcon, RightArrowIcon, SectionTeam, Group, Section } from '../styles/pages/team-page.style';
import Navbar from '../components/NavBar';
import CardTeam from '../components/CardTeam';

import TeamCarousel from '../components/TeamCarousel';

export interface PageTeamProps {
    markdownRemark :{
        frontmatter : TeamBaseProps
    }
}

export interface TeamBaseProps{
    titleSEO : string;
    descriptionSEO: string;
    title : string;
    description : string;
    team : Array<{
        profilePhoto :string;
        name: string;
        office : string;
        description: string;
        socialMedia:{
            linkedin : string;
            instagram :string;
        }
    }>

}
interface TeamProps extends TeamBaseProps   {
    
    location : {pathname : string;};
    preview? : boolean;
}

export  function PageTeamTemplate({titleSEO,descriptionSEO ,title ,description, team, location, preview } :TeamProps ){

return (<>
       {!preview?(<SEO title={titleSEO} description={descriptionSEO} pathname={location.pathname} />) :''}

            <Container>
                <Description>
                    <H1 size={"S4"} color={"primary"}>{title}</H1>
                    
                    <p>{description}</p>
                </Description>
                <Group>
                    <TeamCarousel team={team} />
                </Group>
                
            </Container>		
            <Logo />
    </>);}

const  PageTeam = ({data, location} :PageProps<PageTeamProps>)=>{
    const content = data.markdownRemark.frontmatter;
 return(
     <PageTeamTemplate
     descriptionSEO={content.descriptionSEO}
     team={content.team}
     titleSEO={content.titleSEO}
     title={content.title}
     description={content.description}
     location={location}
     />
 );
 }
export default PageTeam;

export const query = graphql`
    query TeamPageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "team-page"}}) {
        frontmatter {
            titleSEO 
            descriptionSEO
            title 
            description 
            team {
                profilePhoto
                name
                office 
                description
                socialMedia {
                    linkedin 
                    instagram    
                }
            }
        }
    }
    }
`;