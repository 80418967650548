import React from 'react';
import styled from 'styled-components';

import Arrow from '../../assets/icons/blueArrow.svg';

export const ArrowUpIcon = styled.div`
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url(${Arrow});
  background-size: cover;
  transform: rotate(-270deg);
  cursor: pointer;

  @media(min-width: 792px) {
    display: none;
  }
`;

export const ArrowDownIcon = styled.div`
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url(${Arrow});
  background-size: cover;
  transform: rotate(-90deg);
  cursor: pointer;

  @media(min-width: 792px) {
    display: none;
  }
`;

export const ArrowLeftIcon = styled.div`
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url(${Arrow});
  background-size: cover;
  cursor: pointer;
  margin-right: 15px;

  @media(max-width: 791px) {
    display: none;
  }
`;

export const ArrowRightIcon = styled.div`
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url(${Arrow});
  background-size: cover;
  transform: rotate(-180deg);
  cursor: pointer;

  @media(max-width: 791px) {
    display: none;
  }
`;