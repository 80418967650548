import React, { useEffect, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';

import './styles.css';

import { ArrowUpIcon, ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon } from './styles.ts';
import CardTeam from '../CardTeam';
import { useTheme } from 'styled-components';
import useMediaQuery from '../../Hooks/useMediaQuery';
import { P } from '../../styles/Fonts';

interface TeamCarrocelProps{
    team : Array<{
      profilePhoto :string;
      name: string;
      office : string;
      description: string;
      socialMedia:{
          linkedin : string;
          instagram :string;
      }
  }>
}
export default function TeamCarousel({team} : TeamCarrocelProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width : ${theme.breakpoints.sm}px)`);
  const isSm = useMediaQuery(`(min-width : ${theme.breakpoints.sm}px) and (max-width : 791px)` );
  const isMd = useMediaQuery(`(min-width : 792px) and (max-width : 1110px)`);
  const isLg = useMediaQuery(`(min-width : 1110px) and (max-width : 1500px)`);
  const isXlg = useMediaQuery(`(min-width : 1500px)`);
  console.log(`
  mobile ; ${isMobile} \n
  sm : ${isSm} \n
  md : ${isMd} \n
  lg : ${isLg} \n
  xlg : ${isXlg} \n
  `)
  
  const teamIsValid = Array.isArray(team) && team != undefined;
  if(!teamIsValid){
    return <P size={'S4'} color={'primary'}> No Momento Não temos nenhum Membro do time cadastrado 😥 </P>
  }
  function ReturnOrientation() {
    if(isMobile || isSm) {
      return 'vertical';
    }
    return 'horizontal';
  }

  function ReturnSize() {
    let size = 0;
    if(isXlg){
      size = 4;
    }else if(isLg){
      size = 3;
    }else if(isMd) {
      size = 2;
    }else {
      size = 1;
    }

    return team.length > size? size : team.length;
  
  }


  return (
    <CarouselProvider
    naturalSlideWidth={150}
    naturalSlideHeight={250}
    visibleSlides={ReturnSize()}
    totalSlides={Array.isArray(team)?team.length: 0}
    orientation={ReturnOrientation()}
    className="carouselProviderTeamContainer"
    >

      <ButtonBack className="buttonTeamStyle">
        <ArrowUpIcon />
      </ButtonBack>
      <ButtonBack className="buttonTeamStyle">
        <ArrowLeftIcon />
      </ButtonBack>

      <Slider className="sliderTeamStyle" >
        {team.map((member,index)=>(
             <Slide className="slideTeamStyle"  key={`member.name-${index}`} index={index + 1}>
             <CardTeam member={member}/>
            </Slide>
        ))}
      </Slider>

      <ButtonNext className="buttonTeamStyle">
        <ArrowRightIcon />
      </ButtonNext>
      <ButtonNext className="buttonTeamStyle">
        <ArrowDownIcon />
      </ButtonNext>
    </CarouselProvider>
  );
}