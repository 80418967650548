import React from 'react';

import { Container, Card, ProfilePhoto, TextName, SubName, Description, Icons, InstagramIcon, LinkedinIcon } from './styles';


interface Member {
    profilePhoto :string;
      name: string;
      office : string;
      description: string;
      socialMedia:{
          linkedin : string;
          instagram :string;
      }
}
interface CarTeamProps{
    member : Member;
}
const CardTeam = ({member} : CarTeamProps) => {

    return (
        <Container>
            <Card>
                <ProfilePhoto> <img src={member.profilePhoto} alt={member.name}/>
                </ProfilePhoto>
                <TextName size={'SP'} color={'textTitle'} weight={'800'}>{member.name}</TextName>
                <SubName    size={'SP'} >{member.office}</SubName>
                <Description  size={'SP'} >{member.description}</Description>
                <Icons>
                    <InstagramIcon href={member.socialMedia.instagram} />
                    <LinkedinIcon  href={member.socialMedia.linkedin}/>
                </Icons>
            </Card>
        </Container>
    )
}

export default CardTeam;